// import logo from './logo.svg';
import stanCircle from './StanDanleyCircleMixed.svg'
import './App.css';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <img src={stanCircle} className="App-logo" alt="logo" />
        <p>
          Stan Dan's Plans
        </p>
        <h3 id="sdp-sbs-header">Stan Danley's Stress Ball Stand:</h3>
        <p className="body-text">On display at the Ogden Museum of Southern Art<br></br> from August 6th, 2022 until January 8th, 2023.</p>
        <p className="body-text">Please reach out via Instagram at #standansplans</p>
        <p className="body-text">Stress balls will be coming soon <br></br>to the Ogden Museum Gift Shop beginning September 2022!</p>
        {/* <a
          className="App-link"
          href="https://handlea.com/StanDanley/standansplans.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Stan Danley's Stress Ball Stand
        </a> */}
      </header>
    </div>
  );
}

export default App;
